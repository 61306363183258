import React from "react"
import SmallCardPage from "../../components/SmallCardPage"
import { graphql } from "gatsby"
import LargeCard from "../../components/LargeCard"
import Layout from "../../components/Layout"
import LinkButton from "../../components/LinkButton"
import styled from 'styled-components';

const StyledLargeCard = styled.div`
  .testimonials > .container > .testimonials-inner > .testimonials-wrap > .testimonial > .cta {
    padding-bottom:20px;
  }
`;

export const query = graphql`
  query getFacilitators {
      markdownRemark(frontmatter: {url: {eq: "facilitators"}}) {
    id
    frontmatter {
      title
      subtitle
      card {
        title
        subtitle
        thumbnail
        url
        content
      }
    }
  }
  }
`

export default function SplashPage({data}) {
  return  <Layout showCta={false} b2c={true} title={data.markdownRemark.frontmatter.title} subtitle={data.markdownRemark.frontmatter.subtitle}>
    <StyledLargeCard>
      <LargeCard cards={data.markdownRemark.frontmatter.card} cta={<LinkButton to={"organizations"}>Learn More</LinkButton>}/>
    </StyledLargeCard>
  </Layout>

}



